import React, { useState, useContext } from 'react';
import { Button, Container, Row, Col, Dropdown } from 'react-bootstrap';

import { CartContext } from '../context/Cart';

import '../styles/components/selectableVariants.css';

const SelectableVariants = ({ variants }) => {
  // Initialize state with the first variant object in the array
  const [selectedVariant, setSelectedVariant] = useState(variants[0]);

  const { addToCart } = useContext(CartContext);

  const handleVariantClick = (variant) => {
    setSelectedVariant(variant);
  };

  const handleAddToCart = (variant) => {
    addToCart(variant);
  };

  return (
    <Container>
      <Row>
        <Col md={12}>
          <Dropdown drop='up'>
            <Dropdown.Toggle size='sm' className='product-variant-dropdown' variant="outline-dark" id="dropdown-basic">
              {selectedVariant?.title}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {variants.map((variant, variantIdx) => (
                <Dropdown.Item
                  key={variantIdx} 
                  onClick={() => setSelectedVariant(variant)}
                >
                  {variant.title}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col md={12}>
          <Button 
            className='add-to-cart-btn mt-2 mb-2' 
            onClick={() => handleAddToCart(selectedVariant)} 
            variant="dark" 
            disabled={!selectedVariant}
            size='sm'
          >
            Add to Cart
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default SelectableVariants;