import { Stack, Image, Spinner } from 'react-bootstrap';
import { useState } from 'react';

import upseto1 from '../assets/images/Gallery/upseto1.jpeg';
import upseto2 from '../assets/images/Gallery/upseto2.jpeg';
import upseto3 from '../assets/images/Gallery/upseto3.jpeg';
import upseto4 from '../assets/images/Gallery/upseto4.jpeg';
import upseto5 from '../assets/images/Gallery/upseto5.jpeg';

//import upsetoVid from '../assets/images/Gallery/upsetoVid1Comp.mp4';

const galleryImagePaths = [
    '/images/Gallery/image00009.jpeg',
    '/images/Gallery/image00024.jpeg',
    '/images/Gallery/image00025.jpeg',
    '/images/Gallery/image00026.jpeg',
    '/images/Gallery/image00029.jpeg',
    '/images/Gallery/image00001.jpeg',
    '/images/Gallery/image00002.jpeg',
    '/images/Gallery/image00003.jpeg',
    '/images/Gallery/image00004.jpeg',
    '/images/Gallery/image00005.jpeg',
    '/images/Gallery/image00006.jpeg',
    '/images/Gallery/image00007.jpeg',
    '/images/Gallery/image00008.jpeg',
    '/images/Gallery/image00010.jpeg',
    '/images/Gallery/image00011.jpeg',
    '/images/Gallery/image00012.jpeg',
    '/images/Gallery/image00013.jpeg',
    '/images/Gallery/image00014.jpeg',
    '/images/Gallery/image00015.jpeg',
    '/images/Gallery/image00016.jpeg',
    '/images/Gallery/image00017.jpeg',
    '/images/Gallery/image00018.jpeg',
    '/images/Gallery/image00019.jpeg',
    '/images/Gallery/image00020.jpeg',
    '/images/Gallery/image00021.jpeg',
    '/images/Gallery/image00023.jpeg',
    '/images/Gallery/image00027.jpeg',
    '/images/Gallery/image00028.jpeg',
    '/images/Gallery/image00022.jpeg'
];

const GalleryPage = () => {
    // Create an array of states to track loading for each image/video
    const [loaded, setLoaded] = useState(Array(6 + galleryImagePaths.length).fill(false));

    // Handle image/video load event
    const handleMediaLoad = (index) => {
        setLoaded(prevState => {
            const newLoadedState = [...prevState];
            newLoadedState[index] = true; // Mark the media as loaded
            return newLoadedState;
        });
    };

    return (
        <Stack>
            {/* First image */}
            <div style={{ position: 'relative' }}>
                {!loaded[0] && (
                    <div style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <Spinner animation="border" />
                    </div>
                )}
                <Image
                    fluid
                    src={upseto1}
                    style={{ width: '100vw', objectFit: 'cover' }}
                    onLoad={() => handleMediaLoad(0)}
                />
            </div>

            {/* Video element */}
            {/* <div style={{ position: 'relative' }}>
                {!loaded[1] && (
                    <div style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <Spinner animation="border" />
                    </div>
                )}
                <video
                    width="100%"
                    controls
                    onLoadedData={() => handleMediaLoad(1)}
                    style={{ display: loaded[1] ? 'block' : 'none' }}
                >
                    <source src={upsetoVid} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div> */}

            {/* Last two static images */}
            {[upseto2, upseto3, upseto4, upseto5].map((src, index) => (
                <div key={index + 2} style={{ position: 'relative' }}>
                    {!loaded[index + 2] && (
                        <div style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}>
                            <Spinner animation="border" />
                        </div>
                    )}
                    <Image
                        fluid
                        src={src}
                        style={{ width: '100vw', objectFit: 'cover' }}
                        onLoad={() => handleMediaLoad(index + 2)}
                    />
                </div>
            ))}

            {/* Dynamic images */}
            {galleryImagePaths.map((galleryImagePath, imageIndex) => (
                <div key={imageIndex + 4} style={{ position: 'relative' }}>
                    {!loaded[imageIndex + 4] && (
                        <div style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}>
                            <Spinner animation="border" />
                        </div>
                    )}
                    <Image
                        fluid
                        src={galleryImagePath}
                        style={{ width: '100vw', objectFit: 'cover' }}
                        onLoad={() => handleMediaLoad(imageIndex + 4)}
                    />
                </div>
            ))}
        </Stack>
    );
};

export default GalleryPage;